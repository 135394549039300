<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">岗位职责管理</h2>
                <div class="RightDiv">
                    <el-form :model="searchForm" class="searForm">
                        <div class="table-title">
                            <div>
                                <el-input v-model="searchForm.nameLike" placeholder="岗位职责名称" clearable prefix-icon="el-icon-search" @keyup.enter.native="searchList" @blur="searchList" @clear="searchList">
                                </el-input>
                            </div>
                            <div style="margin-left:10px">
                                <el-select v-model="searchForm.postVal" placeholder="请选择岗位职责" clearable @change="searcPost(searchForm.postVal)">
                                    <el-option v-for="item in postOptions" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="index-add">
                                <el-button type="primary" @click="depaddKeshi">添加岗位职责</el-button>
                            </div>
                        </div>
                    </el-form>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>

                    <el-table-column prop="name" label="岗位职责名称" align="center">
                    </el-table-column>
                    <el-table-column prop="type" label="类型" align="center" width="80">
                        <template slot-scope="scope">
                            <div v-if="scope.row.type=='0'">医生</div>
                            <div v-else-if="scope.row.type=='1'">护士</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" width="200" align="center">
                        <template slot-scope="scope">
                            <div class="remakSty">
                                {{scope.row.remark}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="更新时间" align="center" width="160">
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="250">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <el-button size="mini" type="danger" @click="deleteKeshi(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击添加科室弹出框 -->
        <el-dialog :title="addName" :visible.sync="AdddialogVisible" width="32%" :before-close="AdddialogVisibleClose">
            <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" :inline="true" label-width="130px">
                <el-form-item label="岗位职责类型：" prop="type">
                    <el-select v-model="AddDateModel.type" placeholder="请选择岗位职责" @change="postChange(AddDateModel.type)">
                        <el-option v-for="item in postOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="岗位职责名称：" prop="name">
                    <el-select v-model="AddDateModel.name" placeholder="请选择岗位名称" @change="findChange(AddDateModel.name)">
                        <el-option v-for="item in findOptions" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="备注：" prop="remark">
                    <el-input type="textarea" v-model="AddDateModel.remark"></el-input>
                </el-form-item>

                <el-form-item class="depar-dia btnCen">
                    <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="parmen">{{btnText}}</el-button>
                    <el-button v-else type="info" :loading="addDepClickKing"></el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
export default {
    data() {
        return {
            btnText: "添加",
            addName: "添加岗位职责",
            postOptions: [
                {
                    value: "0",
                    label: "医生",
                },
                {
                    value: "1",
                    label: "护士",
                },
            ],
            findOptions: [],
            // 添加科室的添加按钮
            addDepClick: true,
            addDepClickKing: false,
            // 查询用的业务名称
            searchForm: {
                nameLike: "",
                postVal: "",
            },
            AddDateModel: {
                name: "",
                type: "",
                remark: "",
            },
            AddDateRules: {
                // remark: [
                //     {
                //         required: true,
                //         message: "请输入备注",
                //         trigger: "blur",
                //     },
                // ],
                name: [
                    {
                        required: true,
                        message: "请选择岗位职责名称",
                        trigger: "change",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "请选择岗位职责名称",
                        trigger: "change",
                    },
                ],
            },
            // 点击添加科室弹出框
            AdddialogVisible: false,
            // 科室管理的分页
            // 当前页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 总数
            total: 1,
            // 表格数据
            tableData: [],
            // 医院id
            hospitalId: window.sessionStorage.getItem("hospitalId"),
            // 科室用户点击修改的id
            userId: "",
            posId: "",
            // 科室id
            keshiUserid: "",
        };
    },
    created() {
        this.getDepartmentList();
    },
    methods: {
        findChange(val) {
            this.AddDateModel.name = val;
        },
        postChange(val) {
            this.AddDateModel.type = val;
            this.AddDateModel.name = "";
            // console.log(val);
            if (val == "0") {
                // 医生
                this.findBySys("doctor_post_responsibility");
            } else if (val == "1") {
                // 护士
                this.findBySys("nurse_post_responsibility");
            }
        },
        async findBySys(dicData) {
            let data = {
                dictType: dicData,
                status: "0",
            };
            let res = await this.$axios.findBySys(data);
            // console.log(res);
            if (res.status == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                this.findOptions = res.data;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 搜索框
        searchList() {
            this.pageNum = 1;
            this.getDepartmentList();
        },
        searcPost(val) {
            this.pageNum = 1;
            this.searchForm.postVal = val;
            this.getDepartmentList();
        },
        // 科室管理分页
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.getDepartmentList();
        },
        // 点击添加科室
        depaddKeshi() {
            this.AdddialogVisible = true;
        },
        // 点击添加科室的时候取消正则
        AdddialogVisibleClose() {
            this.addName = "添加岗位职责";
            this.btnText = "添加";
            this.AdddialogVisible = false;
            this.$refs.AddDateRef.resetFields();
            this.findOptions = [];
            this.AddDateModel = {
                name: "",
                type: "",
                remark: "",
            };
        },
        parmen() {
            if (this.btnText == "添加") {
                this.addKeshi();
            } else if (this.btnText == "修改") {
                this.editdialogKeshi();
            }
        },
        // 点击操作添加科室的添加
        addKeshi() {
            this.$refs.AddDateRef.validate((valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                    let data = _qs.stringify({
                        type: this.AddDateModel.type,
                        name: this.AddDateModel.name,
                        remark: this.AddDateModel.remark,
                    });
                    this.addDepartment(data);
                }
            });
        },
        // 点击操作删除
        async deleteKeshi(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                id: row.id,
            });
            let { data: res } = await this.$axios.hospostDelete(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.getDepartmentList();
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 点击操作修改科室的添加
        editKeshi(row) {
            // console.log(row);
            this.addName = "修改岗位职责";
            this.btnText = "修改";
            this.posId = row.id;

            this.AddDateModel.type = row.type + "";
            this.AddDateModel.name = row.name;

            if (row.type == "0") {
                // 医生
                this.findBySys("doctor_post_responsibility");
            } else if (row.type == "1") {
                // 护士
                this.findBySys("nurse_post_responsibility");
            }

            this.AddDateModel.remark = row.remark;
            this.AdddialogVisible = true;
        },
        // 点击修改科室用户管理的添加
        editdialogKeshi() {
            this.$refs.AddDateRef.validate((valid) => {
                if (!valid) return;
                this.addDepClick = false;
                this.addDepClickKing = true;
                let data = _qs.stringify({
                    name: this.AddDateModel.name,
                    type: this.AddDateModel.type,
                    remark: this.AddDateModel.remark,
                    id: this.posId,
                });
                this.hospostUpdate(data);
            });
        },
        async hospostUpdate(data) {
            let res = await this.$axios.hospostUpdate(data);
            // console.log(res);
            this.addDepClick = true;
            this.addDepClickKing = false;
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                this.AdddialogVisibleClose();
                this.getDepartmentList();
                this.$message({
                    message: res.data.msg,
                    type: "success",
                });
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        // 添加或修改科室信息接口
        async addDepartment(data) {
            let res = await this.$axios.hospostlistAdd(data);
            // console.log(res);
            this.addDepClick = true;
            this.addDepClickKing = false;
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                this.AdddialogVisibleClose();
                this.getDepartmentList();
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 分页显示科室管理数据
        async getDepartmentList() {
            let data = {
                deleteFlag: "0",
                name: this.searchForm.nameLike, //查询用的 科室名称
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                type: this.searchForm.postVal,
            };
            let { data: res } = await this.$axios.hospostList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            }
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
    text-align: inherit;
    .btnCen {
        width: 100%;
        text-align: center;
    }
}

.RightDiv {
    margin: 0 auto;
}
.searForm {
    float: right;
}
.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.depart-addnum {
    width: 100%;
}

.depar-dia {
    width: 100%;
}

/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}
.el-main {
    .table-title {
        display: flex;
        margin-bottom: 10px;
        .index-add {
            margin-left: 20px;
        }
    }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

/deep/ .el-textarea__inner {
    width: 300px !important;
}
</style>
